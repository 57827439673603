<template>
<div class="ui grid">
  <div class="row">
    <div class="eight wide column">
      <soutenance-template :soutenance="false"/>
    </div>

    <div class="eight wide column">
      <soutenance-template :soutenance="true"/>
    </div>
  </div>
</div>
</template>

<script>
const tableType1 = () => import("@/components/generic/tableType1")
const soutenanceTemplate = () => import("./soutenance_template")
export default {
  name: "soutenance",
  components:{soutenanceTemplate},
  data(){
    return{
      // tableSoutenance: {
      //   action: false,
      //   data: [],
      //   headers: [
      //     {label: "#", value: "_id",filter:(id)=>this.tableSoutenance.data.findIndex(e=>e._id===id)+1},
      //     {label: "Date", value: "date",filter:(data)=>this.$SHARED.utils.getDateOnly(data)},
      //     {label: "status", value: "status"}
      //   ],
      //   pagination: {
      //     page: 1,
      //     limit: 10,
      //     count: 0
      //   },
      //   isBusy: false,
      //   selectedStudent: {}
      // },
      // tablePreSoutenance: {
      //   action: false,
      //   data: [],
      //   headers: [
      //     {label: "#", value: "_id",filter:(id)=>this.tablePreSoutenance.data.findIndex(e=>e._id===id)+1},
      //     {label: "Date", value: "date",filter:(data)=>this.$SHARED.utils.getDateOnly(data)},
      //     {label: "status", value: "status"}
      //   ],
      //   pagination: {
      //     page: 1,
      //     limit: 10,
      //     count: 0
      //   },
      //   isBusy: false,
      //   selectedStudent: {}
      // }
    }
  },
  computed:{
    CURRENT_USER(){return this.$store.state.current_user}
  },
  methods:{
    // setPageSoutenance(page) {
    //   console.log("page: " + page)
    //   this.tableSoutenance.pagination.page = page
    //   this.getSoutenanceList()
    // },
    // setPagePreSoutenance(page) {
    //   console.log("page: " + page)
    //   this.tablePreSoutenance.pagination.page = page
    //   this.getPreSoutenanceList()
    // },
    // getSoutenanceList() {
    //   console.log('getSoutenance');
    //   const data = {
    //     query: {
    //       user_id: this.CURRENT_USER._id,
    //       type:'soutenance'
    //     },
    //     options: {
    //       limit: this.tableSoutenance.pagination.limit,
    //       page: this.tableSoutenance.pagination.page,
    //       withCount: true
    //     }
    //   };
    //   this.tableSoutenance.isBusy = true;
    //   this.$store.dispatchAsync(this.$SHARED.services.demandeSoutenance.list, data).then(ar => {
    //     this.tableSoutenance.data = ar.docs;
    //     this.tableSoutenance.pagination.count = ar.count;
    //   }).finally(() => {
    //     this.tableSoutenance.isBusy = false;
    //   })
    // },
    // getPreSoutenanceList() {
    //   console.log('getPreSoutenance');
    //   const data = {
    //     query: {
    //       user_id: this.CURRENT_USER._id,
    //       type:'presoutenance'
    //     },
    //     options: {
    //       limit: this.tablePreSoutenance.pagination.limit,
    //       page: this.tablePreSoutenance.pagination.page,
    //       withCount: true
    //     }
    //   };
    //   this.tablePreSoutenance.isBusy = true;
    //   this.$store.dispatchAsync(this.$SHARED.services.demandeSoutenance.list, data).then(ar => {
    //     this.tablePreSoutenance.data = ar.docs;
    //     this.tablePreSoutenance.pagination.count = ar.count;
    //   }).finally(() => {
    //     this.tablePreSoutenance.isBusy = false;
    //   })
    // }
  },
  mounted() {

    // this.getSoutenanceList()
    // this.getPreSoutenanceList()
  }
}
</script>

<style scoped>

</style>